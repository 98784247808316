import React from "react";
import {
  Phone,
  Mail,
  Calendar,
  Users,
  DollarSign,
  Award,
  Briefcase,
} from "lucide-react";
import { node } from "prop-types";

const Hackathon = () => {
  return (
    <div className="min-h-screen bg-[#460073] text-white p-4 sm:p-8 font-sans overflow-x-hidden">
      <div className="max-w-5xl mx-auto space-y-16 relative">
        <div className="absolute top-0 right-0 w-1/2 h-full bg-white opacity-5 transform -skew-x-12 z-0"></div>

        <header className="text-center relative z-10 animate-fade-in">
          <h1 className="text-4xl sm:text-6xl font-extrabold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-pink-400">
            InnovateX Hackathon 2024
          </h1>
          <p className="text-xl sm:text-2xl text-pink-200 mb-10">
            Transforming Ideas into Future Solutions
          </p>
          <button className="bg-pink-500 hover:bg-pink-600 text-white font-bold py-3 px-6 sm:py-4 sm:px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"type="button"
    onClick={(e) => {
      e.preventDefault();
      window.location.href='https://forms.gle/baAC8YGCDG9oaRJW6';
      }}>
            Register Now
          </button>
        </header>

        <section className="space-y-6 relative z-10 animate-fade-in">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6 border-b-2 border-pink-400 pb-2 inline-block">
            About the Event
          </h2>
          <p className="text-base sm:text-lg text-pink-100 leading-relaxed">
            InnovateX Hackathon 2024 is a multidisciplinary event aimed at
            inspiring college students to develop innovative solutions across a
            wide range of domains. Participants will have the opportunity to
            showcase their problem-solving skills, collaborate in teams, and
            work on real-world challenges in cutting-edge fields. This hackathon
            is designed to foster creativity and collaboration while paving the
            way for future innovators.
          </p>
        </section>

        <section className="space-y-6 relative z-10 animate-fade-in">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6 border-b-2 border-pink-400 pb-2 inline-block">
            Theme / Domain
          </h2>
          <p className="mb-6 text-base sm:text-lg text-pink-100">
            Participants can choose from the following 18 themes to develop
            their solutions:
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {[
              "AR/VR",
              "Agri Tech",
              "Bio Tech",
              "Med Tech",
              "Cloud Computing",
              "Renewable Energy",
              "Edge Computing",
              "Artificial Intelligence",
              "Enterprise Solutions",
              "Internet of Things (IoT)",
              "Industry 4.0",
              "Networking & Security",
              "E-vehicles",
              "Robotics",
              "Aerospace",
              "Gaming",
              "Waste Management",
              "Sustainability Management",
            ].map((theme, index) => (
              <div
                key={index}
                className="bg-pink-500 bg-opacity-20 p-4 rounded-lg shadow-lg hover:bg-opacity-30 transition-all duration-300 text-center group"
              >
                <span className="text-pink-200 group-hover:text-white transition-colors duration-300">
                  {theme}
                </span>
              </div>
            ))}
          </div>
        </section>

        <section className="space-y-6 relative z-10 animate-fade-in">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6 border-b-2 border-pink-400 pb-2 inline-block">
            Event Details
          </h2>
          <ul className="space-y-4">
            {[
              {
                icon: <Calendar className="text-pink-400 flex-shrink-0" />,
                text: "Online Mode",
              },
              {
                icon: <Users className="text-pink-400 flex-shrink-0" />,
                text: "Open to college students across all disciplines",
              },
              {
                icon: <Users className="text-pink-400 flex-shrink-0" />,
                text: "Up to 9 members per team",
              },
              {
                icon: <DollarSign className="text-pink-400 flex-shrink-0" />,
                text: "₹100 registration fee per team member",
              },
            ].map((item, index) => (
              <li
                key={index}
                className="flex items-center space-x-4 bg-pink-500 bg-opacity-10 p-4 rounded-lg shadow-md hover:bg-opacity-20 transition-all duration-300"
              >
                {item.icon}
                <span className="text-base sm:text-lg">{item.text}</span>
              </li>
            ))}
          </ul>
        </section>

        <section className="space-y-6 relative z-10 animate-fade-in">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6 border-b-2 border-pink-400 pb-2 inline-block">
            Important Dates
          </h2>
          <ul className="space-y-4">
            {[
              { date: "31st October 2024", event: "Registration Deadline" },
              { date: "15th November 2024", event: "Idea Submission" },
              {
                date: "18th November 2024",
                event: "Result of Idea Evaluation",
              },
              { date: "12th December 2024", event: "Level 1 Evaluation" },
              { date: "27th December 2024", event: "Level 2 Evaluation" },
              { date: "23rd & 24th January 2025", event: "Grand Finale" },
            ].map((item, index) => (
              <li
                key={index}
                className="flex flex-col sm:flex-row sm:items-center sm:justify-between bg-pink-500 bg-opacity-10 p-4 rounded-lg shadow-md hover:bg-opacity-20 transition-all duration-300"
              >
                <span className="font-semibold text-base sm:text-lg mb-2 sm:mb-0">
                  {item.event}
                </span>
                <span className="text-pink-300">{item.date}</span>
              </li>
            ))}
          </ul>
        </section>

        <section className="space-y-6 relative z-10 animate-fade-in">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6 border-b-2 border-pink-400 pb-2 inline-block">
            Benefits
          </h2>
          <ul className="space-y-4">
            {[
              {
                icon: <Award className="flex-shrink-0" />,
                text: "₹10,000, a trophy, and a certificate of achievement for each Theme Winner",
              },
              {
                icon: <DollarSign className="flex-shrink-0" />,
                text: "Up to ₹2,00,000 funding support for potential projects",
              },
              {
                icon: <Briefcase className="flex-shrink-0" />,
                text: "Incubation support for promising solutions",
              },
              {
                icon: <Users className="flex-shrink-0" />,
                text: "Internship opportunities for 3 or 6 months",
              },
              {
                icon: <Award className="flex-shrink-0" />,
                text: "Job offers for standout performers",
              },
            ].map((item, index) => (
              <li
                key={index}
                className="flex items-start space-x-4 bg-pink-500 bg-opacity-10 p-4 rounded-lg shadow-md hover:bg-opacity-20 transition-all duration-300"
              >
                <span className="text-pink-400 mt-1">{item.icon}</span>
                <span className="text-base sm:text-lg">{item.text}</span>
              </li>
            ))}
          </ul>
        </section>

        <section className="space-y-6 relative z-10 animate-fade-in">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6 border-b-2 border-pink-400 pb-2 inline-block">
            Contact Us
          </h2>
          <p className="text-base sm:text-lg text-pink-100 mb-6">
            For inquiries, please contact us at:
          </p>
          <div className="space-y-4">
            <div className="flex items-center space-x-4 bg-pink-500 bg-opacity-10 p-4 rounded-lg shadow-md hover:bg-opacity-20 transition-all duration-300">
              <Mail className="text-pink-400 flex-shrink-0" />
              <span className="text-base sm:text-lg">
                <a href="mailto:info@yarts.in"> info@yarts.in</a>
              </span>
            </div>
            <div className="flex items-center space-x-4 bg-pink-500 bg-opacity-10 p-4 rounded-lg shadow-md hover:bg-opacity-20 transition-all duration-300">
              <Phone className="text-pink-400 flex-shrink-0" />
              <span className="text-base sm:text-lg"><a href="https://wa.me/+918754215242">+91 8754215242</a></span>
            </div>
          </div>
        </section>
        {/* <div>
        <iframe width="100%" height="650px" src="https://docs.google.com/forms/d/e/1FAIpQLSe2WcbxwhXsnO-guCayuYlqW2SlWpMUcfLmHrdWJGSyIiWZnQ/viewform?embedded=true"  style={{border:'none',maxWidth:'100%',maxHeight:'100vh'}}  allowfullscreen webkitallowfullscreen mozallowfullscreen > </iframe>
        </div> */}
      </div>
    </div>
  );
};

export default Hackathon;